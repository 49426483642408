.app-sidebar{
display: flex;
flex-direction: column;
row-gap: 20px;
background-color: #5861AE;
width:60px;
justify-content: flex-start;
align-items: center;
padding-top: 10px;
border:1px solid #707070;
}

#side1,#side2{
width:22px;
height:22px;
background-color: #5861AE;
}

.app-side-icon{
width:60px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
}
    
.icon-active{
width:100%;
height:100%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
padding:10px 0;
} 

    
.icon-active{
width:100%;
height:100%;
background-color: blue;
display: flex;
justify-content: center;
align-items: center;
padding:10px 0;
}


#side3{
width:19px;
height:25px;
margin: 0 5px;
}