.app-navbar{
display: flex;
width: 100%;
height: 80px;
background-color: #FFFFFF;
box-shadow: 0px 2px 48px #0000000F;
opacity: 1;
justify-content: space-between;
align-content: center;
}

.app-navbar h1{
width: 150px;
padding-left: 20px;
/* font: normal normal 900 20px/27px Avenir; */
letter-spacing: 1.8px;
color: #5861AE;
text-transform: uppercase;
opacity: 1;
}

.app-navlinks{
display: flex;
width:30%;
height: 100%;
justify-content: flex-end;
align-items: center;
}

.nav-static-links{
display: flex;
width: 100%;
height: 100%;
background-color: #FFFFFF;
opacity: 1;
text-decoration: none;
justify-content: center;
align-items: center;
outline: none;
border-left: 1px solid #E3E3E3;
}

.nav-links{
background:#5861AE;
color:white;
width: 100%;
height: 100%;
text-decoration: none;
outline: none;
display: flex;
justify-content: center;
align-items: center;
}

.nav-user{
width: 200%;
height: 100%;
background:#5861AE;
color:white;
display: flex;
justify-content: center;
align-items: center;
}

.nav-user img{
width:50px;
height:50px;
border-radius: 50%;
margin:0 5px;
}