.order-success-summary{
display: flex;
justify-content: flex-start;
align-items: flex-start;
width:700px;
height:900px;
position: absolute;
right:0;
top:0;
background-color: white;
}

.order-popup{
position:absolute;
display:flex;
justify-content:center;
align-items: center;
top:200px;
margin-right:500px;
background-color:white;
width:500px;
height:500px;
box-shadow: 0 0 6px gray;
border-radius: 5px;
}
.app-checkout {
width: 100%;
display: flex;
flex-direction: column;
}
.app-checkout h2 {
height: 46px;
background-color: #5861AE;
letter-spacing: 5px;
color: #FFFFFF;
opacity: 1;
margin-left: 23px;
font-size: x-large;
margin-top: 10px;
}
.app-checkout-heading {
width: 100%;
background-color: #5861AE;
color:white;
display: flex;
height: 60px;
justify-content: space-between;
align-items: center;
}

.app-checkout-heading .close {
color: white;
font-size: xx-large;
}

.user-address {
display: flex;
width:100%;
justify-content: space-around;
align-items: center;
background-color: #F4F9FF;
}

.store-address,.store-location,.user-phone{
width:33%;
display: flex;
flex-direction:column ;
padding: 10px;
}

.store-address h3 {
text-align: left;
font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0.38px;
color: #1B2734;
opacity: 1;
}
.user-address input {
height: 20px;
border: none;
border-bottom: 1px solid #E3DFD9;
border-color: #1B2734;
background-color: #F4F9FF;
outline: none;
width: 140px;
margin:0 0 20px 0
}
    
.checkout-items{
width: 100%;
}
.checkout-items tr{
display: flex;
justify-content: space-evenly;
align-items: center;
width:100%;
border-bottom: 1px dashed #707070;
}
.checkout-items td{
width:33%;
}
.checkout-costs{
display: flex;
justify-content: flex-end;
align-items: center;
padding: 5px 20px 5px 0 
}

.checkout-costs p{
border-bottom: 1px dotted #707070;   
}

.checkout-address{
padding-left: 10px;
}
.checkout-address p{
width:10vw;
border: 1px solid #5861AE;
border-radius: 5px;
padding:10px;
margin-bottom:10px;
}

.app-checkout button{
position: absolute;
bottom:20px;
right:20px;
}