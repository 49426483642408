.app-cancelorder{
position: absolute;
width:600px;
height:400px;
top:0;
left:0;
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
border: 1px solid gray;
}

.cancelled {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.cancel-heading{
background-color: #5861AE;
color:white;
display: flex;
justify-content: space-between;
align-items: center;
width: 96.8%;
font-size: large;
padding:10px;
position: absolute;
top:0;
border-radius: 5px;
}


.cancel-alert {
display: flex;
width:100%;
height:100%;
margin-bottom:40px ;
}

.cancel-alert h1{
word-wrap: break-word;
}

.div-cancel-img{
height:100%;
}

.cancelled img{
width:50px;
height:50px;
}

.cancel-text{
display: flex;
flex-direction: column;
margin-left: 20px;
}

.cancelled button {
background: white;
color: #5861AE;
border-radius: 4px;
border: 2px solid #4552C1;
text-decoration: none;
}


.cancelled button:hover {
background-color: #5861AE;
color:white
}
