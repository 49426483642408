.app-past-orders{
width: 100%;
display: flex;
min-height: 800px;
}

.app-orders{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
margin: 20px 0;
}

.past-heading{
display: flex;
justify-content:space-between;
align-items: center;
width:80%;
margin-bottom: 20px;
}

.past-heading input{
outline: none;
text-decoration: none;
border: none;
border-bottom: 2px solid #A0A0A0;
}

.app-orders table{
border-collapse: collapse;
}

.app-orders thead tr{
background-color:#383737 ;
color: white;
}
.app-orders th,td{
padding:20px;
}
.app-orders tbody tr{
border-bottom: 1px solid #707070;
}

.order-odd{
background-color: #FAFAFA;
}

.order-even{
background-color:white ;
}

.app-orders td img{
width:25px;
height:25px;
}

.app-orders .order-view-eye{
display: flex;
justify-content: center;
align-items: center;
}

.app-blank-order{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100%;
height:100%;
}

.app-blank-order p{
margin-bottom: 20px;
}

.app-blank-order button{
color: #4552C1;
background-color: #f7f5f5;
border-radius: 4px;
border:1px solid #5861AE;
padding:5px 10px;
margin-left:0;
}

/* .cancel-summary{
display: flex;
justify-content: flex-start;
align-items: center;
width:700px;
height:900px;
position: absolute;
right:0px;
top:0px;
} */