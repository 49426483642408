.Successful {
text-align: center;
padding: 40px 0;
margin-bottom: 52px;
}
.checkmark {
color: #5861AE;
font-size: 50px;
line-height: 72px;
padding: 5px 20px 5px;
background-color: #5861AE60;
border-radius: 200px;
height: 200px;
width: 200px;
}
.checkmark {
color: #5861AE;
font-size: 50px;
padding: 5px 20px 5px;
background-color: #5861AE60;
border-radius: 200px; height:200px;
width:200px;
}
.Successful button {
margin-top: 16px;
margin-left: 10px;
width: 180px;
height: 35px;
background: #5861AE 0% 0% no-repeat padding-box;
color: white;
border-radius: 22px;
}





